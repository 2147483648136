@import "~bootstrap/scss/bootstrap";
@import 'react-toastify/dist/ReactToastify.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$primary-color: #171159;
$primary-light-color: #F4F7FE;
$gray-color: #A3AED0;

@mixin breakpoint($class) {
  @if $class==xs {
      @media (max-width: 540px) {
          @content;
      }
  }
  @else if $class==sm {
      @media (max-width: 768px) {
          @content;
      }
  }
  @else if $class==md {
      @media (max-width: 992px) {
          @content;
      }
  }
  @else if $class==lg {
      @media (max-width: 1200px) {
          @content;
      }
  }
  @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

* {
  font-family: 'Poppins', sans-serif;
}

/* Button */
.btn-primary {
  background-color: $primary-color;
  border: 0;
  padding: 10px 25px;
}
.btn-secondary {
  border: 0;
  padding: 10px 25px;
}
.icon-btn {
  background-color: $primary-light-color;
  border: 1px solid $primary-light-color;
  color: $primary-color;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 15px;
  &:hover {
    border-color: $primary-color;
  }
}
.sidebar-btn {
  background-color: transparent;
  color: $primary-color;
  padding: 5px 10px;
  border: 2px solid $primary-color;
  border-radius: 5px;
  font-size: 18px;
}

/* Background */
.bg-primary-light {
  background-color: $primary-light-color;
}

/* Text */
.heading-blue {
  color: $primary-color;
  font-weight: 700;
}
.text-primary {
  color: $primary-color !important;
}

/* Link */
.link {
  color: $primary-color;
  text-decoration: none;
}

/* Public Layout */
.public-layout {
  width: 100vw;
  .side {
    height: 100vh;
    width: 50%;
    @include breakpoint(md) {
      width: 100%;
      height: auto;
    }
  }
  .static-side {
    background-color: $primary-color;
    .logo {
      width: 120px;
    }
    .bottles-img {
      width: 70%;
    }
  }
}

/* Protected Layout */
.protected-layout {
  .content {
    width: 78%;
    height: 100vh;
    overflow-y: auto;
    @include breakpoint(md) {
      width: 100%;
    }
  }
}

/* Sidebar */
.sidebar {
  position: relative;
  width: 22%;
  height: 100vh;
  z-index: 100;
  @include breakpoint(md) {
    position: absolute;
    width: 80%;
    background-color: white;
  }
  .logo {
    background-color: #fff;
    width: 60px;
    border-radius: 15px;
    padding: 8px;
  }
  .nav-menu {
    height: 70vh;
    overflow-y: auto;
    .nav-item {
      .nav-link {
        font-size: 18px;
        color: $gray-color;
        border-radius: 8px;
        text-decoration: none;
        padding: 10px 20px;
        svg {
          font-size: 22px;
        }
        &:hover {
          color: white;
        }
        &.active {
          background-color: $primary-color;
          color: white;
          &+.submenu-btn {
            &:hover {
              background-color: rgba(255, 255, 255, 0.403);
              svg {
                color: white;
              }
            }
          }
        }
        .subitem-icon {
          svg {
            font-size: 14px;
          }
        }
      }
      &:last-child {
        position: absolute;
        bottom: 0px;
        left: 10px;
        right: 10px;
        border-top: 1px solid $gray-color;
        border-radius: 0;
      }
      .submenu-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: transparent;
        border: none;
        border-radius: 5px;
        &:hover {
          background-color: $primary-light-color;
          svg {
            color: $primary-color;
          }
        }
        svg {
          color: $gray-color;
        }
      }
    }
  }
}

/* Data Table */
.rdt_TableCell {
  font-size: 15px;
}
.rdt_TableHeadRow {
  .rdt_TableCol {
    font-size: 15px;
    font-weight: 600;
  }
}
.table-img {
  width: 50px;
  height: 50px;
  border: 1px solid $gray-color;
  border-radius: 5px;
}

/* Inputs */
.all-inputs-field {
  .field {
    position: relative;
  }
  .input-icon {
    position: absolute;
    top: 8px;
    left: 8px;
    color: $primary-color;
  }
  input {
    padding: 10px;
  }
  .with-icon {
    padding-left: 45px;
  }
}
.form-switch {
  input {
    font-size: 24px;
    margin-left: -1.4em !important;
    margin-top: 0 !important;
  }
  label {
    margin-left: 10px;
  }
}
.image-placeholder {
  button {
    position: relative;
    width: 120px;
    height: 120px;
    border: 2px solid $gray-color;
    border-radius: 5px;
    span {
      position: absolute;
      top: -8px;
      right: -8px;
      background-color: $primary-color;
      border-radius: 100%;
      font-size: 15px;
      padding: 1px 5px;
    }
  }
  img {
    width: 120px;
    height: 120px;
    border: 2px solid $gray-color;
    border-radius: 5px;
  }
  svg {
    color: $gray-color;
  }
}

/* OTP Form */
.otp-form {
  input {
    width: 20%;
  }
}

/* Icon Box */
.icon-box {
  svg {
    color: $primary-color;
    font-size: 40px;
  }
  p {
    font-size: 16px;
    margin-bottom: 0;
  }
}

/* hr */
.hr-dash {
  margin-top: 20px;
  width: 10%;
  border-color: $primary-color;
  border-style: dashed;
  border-top-width: 2px;
  opacity: 1;
}

/* Data Icon Box */
.data-icon-box {
  width: 18%;
  @include breakpoint(md) {
    width: 23%;
  }
  @include breakpoint(xs) {
    width: 47%;
  }
  .icon {
    background-color: $primary-light-color;
    width: fit-content;
    svg {
      color: $primary-color;
      font-size: 30px;
    }
  }
  .title {
    font-size: 14px;
  }
}

/* Counterman */
.counterman {
  height: 100vh;
  .topbar {
    .logo {
      background-color: $primary-color;
      width: 50px;
      border-radius: 15px;
      padding: 5px;
    }
    button {
      height: fit-content;
    }
  }
  .products-list {
    .category-filter {
      button {
        background-color: rgb(204, 225, 255);
        color: $primary-color;
        padding: 5px 15px;
        &:hover, &.active {
          background-color: $primary-color;
          color: white;
        }
      }
    }
    .products {
      height: 75vh;
      overflow-y: auto;
    }
  }
  .product-box {
    width: 32%;
    cursor: pointer;
    border: 1px solid white;
    &:hover {
      border-color: $gray-color;
    }
    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      background-color: $primary-light-color;
    }
    .category {
      font-size: 14px;
      color: $gray-color;
    }
  }
  .order-list {
    height: 60vh;
    overflow-y: auto;
  }
  .order-item {
    img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      background-color: $primary-light-color;
    }
    .category {
      font-size: 14px;
      color: $gray-color;
    }
    .remove-btn {
      height: fit-content;
      background-color: rgb(255, 182, 182);
      color: red;
      font-size: 12px;
      border-radius: 100%;
      padding: 3px 7px;
    }
    .quantity-controls {
      button {
        font-size: 10px;
        border-radius: 100%;
        padding: 3px 7px;
        color: $primary-color;
        background-color: transparent;
        border: 1px solid lightgray;
        &:disabled {
          background-color: lightgray;
        }
        &:hover {
          background-color: $gray-color;
        }
      }
    }
  }
  .total-bill {
    .label {
      color: $gray-color;
    }
  }
}

.receipt {
  .logo {
    background-color: $primary-color;
    width: 50px;
    border-radius: 15px;
    padding: 5px;
  }
  .receipt-totals {
    width: 40%;
  }
  .paid-stamp {
    width: 100px;
  }
}

@media print {
  * {
    visibility: hidden;
  }
  #print-screen {
    visibility: visible;
    * {
      visibility: visible;
    }
  }
}